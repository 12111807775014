import React from 'react';
import { Route, Routes, BrowserRouter as Router, Link } from "react-router-dom";
import './App.scss';
import "../src/pages/styles/pageStyles.scss";
import "../src/components/styles/componentStyles.scss";
import WelcomePage from './pages/Welcome';
import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import HomePage from './pages/Home';
import HeaderComponent from './components/header/Header';
import FooterComponent from './components/footer/Footer';
import MainComponent from './components/MainComponent';
import MainBackofficeComponent from './components/backoffice/MainBackofficeComponent';
import ResetPasswordPage from './pages/ResetPassword'
import RestorePasswordPage from './pages/RestorePassword';
import NuevaFincaPage from './pages/NuevaFinca';
import NuevaIndustriaPage from './pages/NuevaIndustria';
import NuevoSectorPage from './pages/NuevoSector';
import SectorPage from './pages/Sector';
import AuthProvider from './context/Auth';
import SectoresListPage from './pages/SectoresList';
import { PrivateRoute } from './routes/PrivateRoute';
import { LoggedRoute } from './routes/LoggedRoute';
import { BackofficeRoute } from './routes/BackofficeRoute';
import PrediccionPage from './pages/Prediccion';
import FincasListPage from './pages/FincasList';
import NuevaUnidadProductivaPage from './pages/NuevaUnidadProductiva';
import PrediccionClimaticaPage from './pages/PrediccionClimatica';
import UnidadProductivaPage from './pages/UnidadProductiva';
import DetallesFincaPage from './pages/DetallesFinca';
import AnadirRiegoManualPage from './pages/AnadirRiegoManual';
import LoginBackofficePage from './pages/backoffice/LoginBackoffice';
import HomeBackofficePage from './pages/backoffice/HomeBackoffice';
import paramWrapper from './components/wrappers/ParamWrapper';
import ProgramacionDeRiegoPage from './pages/ProgramacionDeRiego';
import NuevaHuellaPage from './pages/NuevaHuella';
import HuellasListPage from './pages/HuellasList';
import HuellaAguaPage from './pages/HuellaAgua';
import HuellaCultivosPage from './pages/HuellaCultivos';
import HuellasAlmazaraListPage from './pages/HuellasAlmazaraList';
import HuellaAlmazarasPage from './pages/HuellaAlmazaras';
import Error404 from './pages/error404';
import Contenidos from './pages/Contenidos';
import HuellaUnidadesPage from './pages/HuellaUnidades';
import BiodiversidadPage from './pages/Biodiversidad';
import ComunidadesPage from './pages/Comunidades';
import BioVegetalPage from './pages/BioVegetal';
import BioVegetalDetallesPage from './pages/BioVegetalDetalles';
import BioInvertebradosPage from './pages/BioInvertebrados';
import BioVegetalEditarPage from './pages/BioVegetalEditar';
import BioInvertebradosDetallesPage from './pages/BioInvertebradosDetalles';
import BioInvertebradosEditarPage from './pages/BioInvertebradosEditar';
import BioVertebradosPage from './pages/BioVertebrados';
import BioVertebradosDetallesPage from './pages/BioVertebradosDetalles';
import BioVertebradosEditarPage from './pages/BioVertebradosEditar';
import EvolucionPage from './pages/Evolucion';
import AvanzadoPage from './pages/Avanzado';

function App() {

  const Skeleton = (Component, props) => (
    <>
      <HeaderComponent {...props} />
      <MainComponent>
        <Component {...props} />
      </MainComponent>
      <FooterComponent />
    </>
  );

  const BackofficeSkeleton = (Component, props) => (
    <>
      <HeaderComponent {...props} />
      <MainBackofficeComponent>
        <Component {...props} />
      </MainBackofficeComponent>
      <FooterComponent />
    </>
  );

  const HomeComponent = () =>
    Skeleton(HomePage, { page: "home" });
  const HomeBackofficeComponent = () =>
    BackofficeSkeleton(HomeBackofficePage, { page: "homebackoffice" });
  const UnidadProductivaComponent = () =>
    Skeleton(UnidadProductivaPage, { page: "unidadproductiva" });
  const NuevaUnidadProductivaComponent = () =>
    Skeleton(NuevaUnidadProductivaPage, { page: "nuevaunidadproductiva" });
  const DetallesFincaComponent = () =>
    Skeleton(DetallesFincaPage, { page: "detallesfinca" });
  const AnadirRiegoManualComponent = () =>
    Skeleton(AnadirRiegoManualPage, { page: "anadirriego" });
  const NuevaFincaComponent = () =>
    Skeleton(NuevaFincaPage, { page: "nuevaunidadfinca" });
  const NuevaIndutriaComponent = () =>
    Skeleton(NuevaIndustriaPage, { page: "nuevaunidadindustria" });
  const NuevoSectorComponent = () =>
    Skeleton(NuevoSectorPage, { page: "nuevosector" });
  const ListaSectoresComponent = () =>
    Skeleton(SectoresListPage, { page: "listasectores" });
  const SectorComponent = () =>
    Skeleton(SectorPage, { page: "sector" });
  const PrediccionComponent = () =>
    Skeleton(PrediccionPage, { page: "prediccion" });
  const PrediccionClimaticaComponent = () =>
    Skeleton(PrediccionClimaticaPage, { page: "prediccionclimatica" });
  const ProgramacionDeRiegoComponent = () =>
    Skeleton(ProgramacionDeRiegoPage, { page: "programacionderiego" });
  const HuellaAguaComponent = () =>
    Skeleton(HuellaAguaPage, { page: "huellaagua" });
  const NuevaHuellaComponent = () =>
    Skeleton(NuevaHuellaPage, { page: "nuevahuella" });
  const HuellasListComponent = () =>
    Skeleton(HuellasListPage, { page: "huellaslist" });
  const HuellaCultivosComponent = () =>
    Skeleton(HuellaCultivosPage, { page: "huellacultivos" });
  const HuellasAlmazaraListComponent = () =>
    Skeleton(HuellasAlmazaraListPage, { page: "huellasalmazaralist" });
  const HuellaAlmazarasComponent = () =>
    Skeleton(HuellaAlmazarasPage, { page: "huellaalmazaras" });
  const HuellaUnidadesComponent = () =>
    Skeleton(HuellaUnidadesPage, { page: "huellaunidades" });
  const BiodiversidadComponent = () =>
    Skeleton(BiodiversidadPage, { page: "biodiversidad" });
  const ComunidadesComponent = () =>
    Skeleton(ComunidadesPage, { page: "comunidades" });
  const BioVegetalComponent = () =>
    Skeleton(BioVegetalPage, { page: "biovegetal" });
  const BioVegetalDetallesComponent = () =>
    Skeleton(BioVegetalDetallesPage, { page: "biovegetaldetalles" });
  const BioVegetalEditarComponent = () =>
    Skeleton(BioVegetalEditarPage, { page: "biovegetaleditar" });
  const BioInvertebradosComponent = () =>
    Skeleton(BioInvertebradosPage, { page: "bioinvertebrados" });
  const BioInvertebradosDetallesComponent = () =>
    Skeleton(BioInvertebradosDetallesPage, { page: "bioinvertebradosdetalles" });
  const BioInvertebradosEditarComponent = () =>
    Skeleton(BioInvertebradosEditarPage, { page: "bioinvertebradoseditar" });
  const BioVertebradosComponent = () =>
    Skeleton(BioVertebradosPage, { page: "biovertebrados" });
  const BioVertebradosDetallesComponent = () =>
    Skeleton(BioVertebradosDetallesPage, { page: "biovertebradosdetalles" });
  const BioVertebradosEditarComponent = () =>
    Skeleton(BioVertebradosEditarPage, { page: "biovertebradoseditar" });
  const AvanzadoComponent = () =>
    Skeleton(AvanzadoPage, { page: "avanzado" });
  const EvolucionComponent = () =>
    Skeleton(EvolucionPage, { page: "evolucion" });
  const ListaFincasComponent = () =>
    Skeleton(FincasListPage, { page: "listafincas" });
  const Error404Component = () => 
    Skeleton(Error404, { page: "error404" });

  const RouterComponent = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={
            <LoggedRoute>
              <WelcomePage />
            </LoggedRoute>
          } />
          <Route path="/login" element={
            <LoggedRoute>
              <LoginPage />
            </LoggedRoute>
          } />
          <Route path="/registro" element={
            <LoggedRoute>
              <RegisterPage />
            </LoggedRoute>
          } />
          <Route path="/recuperarPassword" element={
            <LoggedRoute>
              <RestorePasswordPage />
            </LoggedRoute>
          } />
          <Route path="/restaurarPassword" element={
            <LoggedRoute>
              <ResetPasswordPage />
            </LoggedRoute>
          } />
          <Route path="/backofficeLogin" element={
            <BackofficeRoute>
              <HeaderComponent />
              <LoginBackofficePage />
              <FooterComponent />
            </BackofficeRoute>
          } />
          <Route path="/backofficeHome" element={
            <BackofficeRoute>
              <HomeBackofficeComponent />
            </BackofficeRoute>
          } />
          <Route path="/home" element={
            <PrivateRoute>
              <HomeComponent />
            </PrivateRoute>
          } />
          <Route path="/unidadProductiva/:name" element={
            <PrivateRoute>
              <UnidadProductivaComponent />
            </PrivateRoute>
          } />
          <Route path="/nuevaUnidadProductiva" element={
            <PrivateRoute>
              <NuevaUnidadProductivaComponent />
            </PrivateRoute>
          } />
          <Route path="/detallesFinca/:id" element={
            <PrivateRoute>
              <DetallesFincaComponent />
            </PrivateRoute>
          } />
          <Route path="/anadirRiego/:id" element={
            <PrivateRoute>
              <AnadirRiegoManualComponent />
            </PrivateRoute>
          } />
          <Route path="/anadirRiego" element={
            <PrivateRoute>
              <AnadirRiegoManualComponent />
            </PrivateRoute>
          } />
          {/*<Route path="/listaFincas" element={
            <PrivateRoute>
              <ListaFincasComponent />
            </PrivateRoute>
          } />*/}
          <Route path="/nuevafinca/finca" element={
            <PrivateRoute>
              <NuevaFincaComponent />
            </PrivateRoute>
          } />
          <Route path="/nuevafinca/industria" element={
            <PrivateRoute>
              <NuevaIndutriaComponent />
            </PrivateRoute>
          } />
          <Route path="/nuevosector/:id" element={
            <PrivateRoute>
              <NuevoSectorComponent />
            </PrivateRoute>
          } />
          <Route path="/listaSectores/:id" element={
            <PrivateRoute>
              <ListaSectoresComponent />
            </PrivateRoute>
          } />
          <Route path="/sector/:id" element={
            <PrivateRoute>
              <SectorComponent />
            </PrivateRoute>
          } />
          <Route path="/prediccion" element={
            <PrivateRoute>
              <PrediccionComponent />
            </PrivateRoute>
          } />
          <Route path="/prediccionclimatica/:id" element={
            <PrivateRoute>
              <PrediccionClimaticaComponent />
            </PrivateRoute>
          } />
          <Route path="/prediccionclimatica" element={
            <PrivateRoute>
              <PrediccionClimaticaComponent />
            </PrivateRoute>
          } />
          <Route path="/programacion-de-riego/:id?" element={
            <PrivateRoute>
              <ProgramacionDeRiegoComponent />
            </PrivateRoute>
          } />
          <Route path="/huella-de-agua" element={
            <PrivateRoute>
              <HuellaAguaComponent />
            </PrivateRoute>
          } />
          <Route path="/nueva-huella/:id?" element={
            <PrivateRoute>
              <NuevaHuellaComponent />
            </PrivateRoute>
          } />
          <Route path="/lista-huellas/:id" element={
            <PrivateRoute>
              <HuellasListComponent />
            </PrivateRoute>
          } />
          <Route path="/lista-huellas-almazara/:id" element={
            <PrivateRoute>
              <HuellasAlmazaraListComponent />
            </PrivateRoute>
          } />
          <Route path="/huella-cultivos/:id?" element={
            <PrivateRoute>
              <HuellaCultivosComponent />
            </PrivateRoute>
          } />
          <Route path="/huella-almazaras/:id?" element={
            <PrivateRoute>
              <HuellaAlmazarasComponent />
            </PrivateRoute>
          } />
          <Route path="/huella-unidades/:id?" element={
            <PrivateRoute>
              <HuellaUnidadesComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/:id?" element={
            <PrivateRoute>
              <BiodiversidadComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/comunidades" element={
            <PrivateRoute>
              <ComunidadesComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/nueva-vegetal" element={
            <PrivateRoute>
              <BioVegetalComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/vegetal/editar/:id" element={
            <PrivateRoute>
              <BioVegetalEditarComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/vegetal/detalles/:id?" element={
            <PrivateRoute>
              <BioVegetalDetallesComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/nueva-invertebrados" element={
            <PrivateRoute>
              <BioInvertebradosComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/invertebrados/editar/:id" element={
            <PrivateRoute>
              <BioInvertebradosEditarComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/invertebrados/detalles/:id?" element={
            <PrivateRoute>
              <BioInvertebradosDetallesComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/nueva-vertebrados" element={
            <PrivateRoute>
              <BioVertebradosComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/vertebrados/detalles/:id?" element={
            <PrivateRoute>
              <BioVertebradosDetallesComponent />
            </PrivateRoute>
          } />
          <Route path="/biodiversidad/vertebrados/editar/:id" element={
            <PrivateRoute>
              <BioVertebradosEditarComponent />
            </PrivateRoute>
          } />
          <Route path="/evolucion/:id?" element={
            <PrivateRoute>
              <EvolucionComponent />
            </PrivateRoute>
          } />
          <Route path="/avanzado" element={
            <PrivateRoute>
              <AvanzadoComponent />
            </PrivateRoute>
          } />

          <Route path="/aviso-legal" exact element={<Contenidos referencia="aviso-legal"/>} />
          <Route path="/proteccion-de-datos" exact element={<Contenidos referencia="proteccion-de-datos"/>} />
          <Route path="/condiciones-de-uso" exact element={<Contenidos referencia="condiciones-de-uso"/>} />
          <Route path="/consentimiento-explicito" exact element={<Contenidos referencia="consentimiento-explicito"/>} />
          <Route path="/aviso-cookies" exact element={<Contenidos referencia="aviso-cookies"/>} />

          <Route path="*" exact element={<Error404 />} />

        </Routes>
      </Router>
    );
  }

  return (
    <AuthProvider>
      <RouterComponent />
    </AuthProvider>
  );
}

export default App;
