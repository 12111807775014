import { Box } from "@mui/material";
import { Link as LinkRouter, useLocation } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import React, { useEffect } from 'react';
import manual from '../assets/manual-usuario-tic4bio.pdf';

const LateralNav = () => {
    const location = useLocation();
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [openBioSubMenu, setOpenBioSubMenu] = useState(false);

    const handleLogout = async (event) => {
        event.preventDefault();
        logout();
        navigate("/login");
    };

    const handleOpenBioMenu = () => {
        setOpenBioSubMenu(!openBioSubMenu);
    }

    const rutasValidas = ['/biodiversidad/comunidades', '/biodiversidad/nueva-vegetal','/biodiversidad/nueva-invertebrados','/biodiversidad/nueva-vertebrados'];

    useEffect(() => {
        if (rutasValidas.includes(location.pathname)) {
          handleOpenBioMenu();
        }
      }, [location.pathname]);

    return (
        
        <Box className="lateralnav-container dsk">
            <div className="lateralnav-principal">
                    <h3 className="lateralnav-head-section">Principal</h3>
                    <ul className="lateralnav-list">
                        <Box component="li">
                            <LinkRouter className="principal" to="/home">Página Inicio</LinkRouter>
                        </Box>
                    </ul>
                </div>
            <div className="lateralnav-caracterizacion">
                <h3 className="lateralnav-head-section">Caracterización</h3>
                <ul className="lateralnav-list">
                    <Box component="li" className={location.pathname === '/nuevaUnidadProductiva' ? 'active' : ''}>
                        <LinkRouter className="nuevaUnidad" to="/nuevaUnidadProductiva">Nueva Unidad</LinkRouter>
                    </Box>
                    <Box component="li" className={location.pathname === '/nueva-huella' ? 'active' : ''}>
                        <LinkRouter className="nuevaHA" to="/nueva-huella">Nueva Huella de Agua</LinkRouter>
                    </Box>
                    <Box component="li" onClick={handleOpenBioMenu} className={rutasValidas.includes(location.pathname) ? 'active' : ''}>
                        <span className="nuevaBIO">Nuevo Índice Biodiversidad</span>
                    </Box>
                    {openBioSubMenu &&
                        <ul className="subNuevaBIO">
                            <Box component="li" className={location.pathname === '/biodiversidad/comunidades' ? 'active' : ''}>
                                <LinkRouter className="bioVegetal" to="/biodiversidad/comunidades">Definir comunidades</LinkRouter>
                            </Box>
                            <Box component="li" className={location.pathname === '/biodiversidad/nueva-vegetal' ? 'active' : ''}>
                                <LinkRouter className="bioVegetal" to="/biodiversidad/nueva-vegetal">Vegetal</LinkRouter>
                            </Box>
                            <Box component="li" className={location.pathname === '/biodiversidad/nueva-invertebrados' ? 'active' : ''}>
                                <LinkRouter className="bioInvertebrados" to="/biodiversidad/nueva-invertebrados">Invertebrados</LinkRouter>
                            </Box>
                            <Box component="li" className={location.pathname === '/biodiversidad/nueva-vertebrados' ? 'active' : ''}>
                                <LinkRouter className="bioVertebrados" to="/biodiversidad/nueva-vertebrados">Vertebrados</LinkRouter>
                            </Box>
                        </ul>
                    }
                    <Box component="li" className={location.pathname === '/anadirRiego' ? 'active' : ''}>
                        
                        <LinkRouter className="añadirRM" to="/anadirRiego">Añadir Riego Manual</LinkRouter>
                        
                    </Box>
                </ul>
            </div>
            <div className="lateralnav-consulta">
                <h3 className="lateralnav-head-section">Consulta</h3>
                <ul className="lateralnav-list">
                    <Box component="li" className={location.pathname === '/home' ? 'active' : ''}>
                        <LinkRouter className="unidades" to="/home">Mis Unidades</LinkRouter>
                    </Box>
                    <Box component="li" className={location.pathname === '/programacion-de-riego' ? 'active' : ''}>
                        <LinkRouter className="añadirRM" to="/programacion-de-riego">Programación de riego</LinkRouter>
                    </Box>
                    <Box component="li" className={location.pathname === '/huella-de-agua' ? 'active' : ''}>
                        <LinkRouter className="HA" to="/huella-de-agua">Huella de Agua</LinkRouter>
                    </Box>
                    <Box component="li" className={location.pathname === '/biodiversidad' ? 'active' : ''}>
                        <LinkRouter className="BIO" to="/biodiversidad">Biodiversidad</LinkRouter>
                    </Box>
                    <Box component="li" className={location.pathname === '/prediccionclimatica' ? 'active' : ''}>
                        <LinkRouter className="infoClima" to="/prediccionclimatica">Información Clima</LinkRouter>
                    </Box>
                    <Box component="li" className={location.pathname === '/evolucion' ? 'active' : ''}>
                        <LinkRouter className="consultasAvanzadas" to="/evolucion">Evolución</LinkRouter>
                    </Box>
                    <Box component="li" className={location.pathname === '/avanzado' ? 'active' : ''}>
                        <LinkRouter className="consultasAvanzadas" to="/avanzado">Avanzado</LinkRouter>
                    </Box>
                </ul>
            </div>
            <div className="lateralnav-cuenta 1" >
                <h3 className="lateralnav-head-section" style={{display: 'none !important'}}>Cuenta</h3>
                <ul className="lateralnav-list">
                    <Box component="li" sx={{display: 'none !important'}}>
                        <LinkRouter className="usuario" to="/">Mi Usuario</LinkRouter>
                    </Box>
                    <Box component="li">
                        <a href="#" className="closeicon" onClick={(e) => handleLogout(e)}>Cerrar Sesión</a>
                    </Box>
                    <Box component="li">
                        <a href={manual} className="downloadicon" download="manual_de_usuario.pdf">
                                Descargar el Manual de usuario
                        </a>
                    </Box>
                </ul>
            </div>
        </Box>
     );
}
 
export default LateralNav;