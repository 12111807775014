import  {Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel} from '@mui/material';
import { useState } from 'react';
import OjoAbierto from "../assets/ojo-abierto.svg";
import OjoCerrado from "../assets/ojo-cerrado.svg";
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import LoadingBackdrop from '../components/LoadingBackdrop';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { instance } from '../utils/axios-config';
import ModalInfo from '../components/ModalInfo';
import logotic4bio from '../assets/lateralmenu/logoheadbio.png';
import logoue from '../assets/logo-UE.png';
import logojunta from '../assets/logo-juntaAndalucia.png';
import Footer from '../components/footer/Footer';
import HeaderOutNavLogin from '../components/headerOutNavLogin';

const RegisterPage = () => {
    
    

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorRegister, setErrorRegister] = useState("");
    const [username, setUsername] = useState("");
    const location = useLocation();
    let prevRoute = location.state;
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(checkForm(data)){
            const body = { 
                nombre: data.get('name'),
                apellido: data.get('surname'),
                email: data.get('email'),
                telefono: data.get('phone'),
                password: data.get('password'),
                consentimiento: (data.get('consentimiento') ? true : false)
            }
            setUsername(data.get('email'));
            try {
                setLoading(true);
                await instance.post(`/users/register`, body).then(() => {
                    setLoading(false);
                    setOpen(true);
                });
            } catch (error) {
                setErrorRegister("Se ha producido un error inesperado, vuelve a intentarlo más tarde.");
                setOpenError(true);
                setLoading(false);
            }
        }
    }

    const handleClickShowPassword = (event) => {
        event.preventDefault();
        setShowPassword((show) => !show)
    };

    const handleHideErrors = () => {
        setErrors(errors => ({...errors, errorEmailMsg: null, errorPasswordMsg: null}));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const checkForm = (data) =>{
        let valid = true;
        setErrors({});
        if(data.get('email') === undefined || data.get('email') === ''){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no puede estar vacío"}));
            valid = false;
        }
        else if((data.get('email') !== undefined && data.get('email') !== '')){
            if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.get('email'))){
                setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es válido"}));
                valid = false;
            }           
        }
        if(data.get('password') === undefined || data.get('password') === ''){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña no puede estar vacía"}));
            valid = false;
        }else if(data?.get('password').length < 3){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña debe ser de al menos 3 dígitos"}));
            valid = false;
        }
        if(data.get('passwordRepeat') === undefined || data.get('passwordRepeat') === ''){
            setErrors(errors => ({...errors, errorPasswordRepeat: true, errorPasswordRepeatMsg: "La contraseña no puede estar vacía"}));
            valid = false;
        }
        if(((data.get('password') !== undefined || data.get('password') !== '') && (data.get('passwordRepeat') !== undefined || data.get('passwordRepeat') !== '')) && data.get('password') !== data.get('passwordRepeat')){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseñas no coinciden. Comprueba que sean iguales."}));
            valid = false;
        }
        if(data.get('surname') === undefined || data.get('surname') === ''){
            setErrors(errors => ({...errors, errorSurname: true, errorSurnameMsg: "El apellido no puede estar vacio"}));
            valid = false;
        }
        if(data.get('name') === undefined || data.get('name') === ''){
            setErrors(errors => ({...errors, errorName: true,errorNameMsg: "El nombre no puede estar vacio"}));
            valid = false;
        }
        return valid;
    }

    return (
        <div className="welcome-main" onClick={handleHideErrors}>
            <div className="welcome-background" >
                <div className="welcome-logos">
                    <div className="welcome-logos-top">
                        <img className="welcome-logo-tic4bio" src={logotic4bio} alt={"Imagen Logo Tic4Bio"}/>
                    </div>
                    <div className="welcome-logos-bottom">
                        <Box className="financian-text">FINANCIAN:</Box>
                        <div className="welcome-logos-bottom-images">
                            <img className="welcome-logo-ue" src={logoue} alt={"Imagen Logo Tic4Bio"}/>
                            <img className="welcome-logo-junta" src={logojunta} alt={"Imagen Logo Tic4Bio"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="welcome-container">
                <HeaderOutNavLogin text={"Registro"} url={prevRoute}/>
                <div className="welcome-body">
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="name">Nombre*</InputLabel>
                            <Input
                                id="name"
                                className=""
                                label="Nombre"
                                required
                                name="name"
                                error={errors.errorName}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                    }
                            />
                            {errors.errorNameMsg && <div className="errorInput">{errors.errorNameMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="surname">Apellido*</InputLabel>
                            <Input
                                id="surname"
                                className=""
                                label="Apellido"
                                required
                                name="surname"
                                error={errors.errorSurname}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                    }
                            />
                            {errors.errorSurnameMsg && <div className="errorInput">{errors.errorSurnameMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="email">Correo electrónico*</InputLabel>
                            <Input
                                id="email"
                                className=""
                                label="Correo electrónico"
                                required
                                name="email"
                                error={errors.errorEmail}
                                startAdornment={
                                <InputAdornment position="start">
                                    <MailOutlineIcon />
                                </InputAdornment>
                                }
                            />
                            {errors.errorEmailMsg && <div className="errorInput">{errors.errorEmailMsg}</div>}
                        </FormControl>
                        {/*<FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                <InputLabel htmlFor="phone">Teléfono*</InputLabel>
                                <Input
                                    id="phone"
                                    className=""
                                    label="Teléfono"
                                    required
                                    name="phone"
                                    error={errors.errorPhone}
                                    startAdornment={
                                    <InputAdornment position="start">
                                        <PhoneIcon />
                                    </InputAdornment>
                                    }
                                />
                                {errors.errorEmailPhoneMsg && <div className="errorInput">{errors.errorEmailPhoneMsg}</div>}
                                {errors.errorPhoneMsg && <div className="errorInput">{errors.errorPhoneMsg}</div>}
                        </FormControl>*/}
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="password">Contraseña*</InputLabel>
                            <Input
                                id="password"
                                required
                                className=""
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                error={errors.errorPassword}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="Conmuta la visibilidad de la contraseña"
                                    onClick={handleClickShowPassword}
                                    edge="end"><img
                                    title={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                    src={showPassword ? OjoCerrado : OjoAbierto}
                                    alt={"Icono visibilidad"}
                                    />
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Contraseña"
                            />
                            {errors.errorPasswordMsg && <div className="errorInput">{errors.errorPasswordMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="passwordRepeat">Repetir contraseña*</InputLabel>
                            <Input
                                id="passwordRepeat"
                                required
                                className=""
                                type={showPassword ? 'text' : 'password'}
                                name="passwordRepeat"
                                error={errors.errorPasswordRepeat}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="Conmuta la visibilidad de la contraseña"
                                    onClick={handleClickShowPassword}
                                    edge="end"><img
                                    title={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                    src={showPassword ? OjoCerrado : OjoAbierto}
                                    alt={"Icono visibilidad"}
                                    />
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Repetir Contraseña"
                            />
                            {errors.errorPasswordRepeatMsg && <div className="errorInput">{errors.errorPasswordRepeatMsg}</div>}
                        </FormControl>
                        <FormControl>
                            <FormControlLabel control={<Checkbox name="consentimiento"/>} label={
                                <span style={{color: "rgba(0, 0, 0, 0.6)", fontSize: "13px", fontWeight: "900"}}>
                                    El interesado consiente la cesión de sus datos en los términos expuestos.{' '}
                                    <Link to="/consentimiento-explicito" target="_blank" style={{color: "#80C606"}}>
                                        Ver aquí
                                    </Link>
                                </span>
                            } />
                        </FormControl>
                        <div className='login-buttons'>
                            <button type="submit" className="btn white">
                                Registrarse
                            </button>
                            <div className="login-buttons-separator">
                                <div className="login-buttons-line"></div>
                                <div className="login-buttons-or">o</div>
                                <div className="login-buttons-line"></div>
                            </div>
                            <div className="button green">
                                <Link to="/login" state={location.pathname}>Iniciar sesión</Link>
                            </div>
                        </div>
                        
                    </Box>
                </div>
            </div>
            <LoadingBackdrop loading={loading} />
            <ModalInfo open={openError} setOpen={setOpenError} contentTitle={"Error"} contentText={errorRegister}/>

            <Dialog open={open} onClose={handleClose} className="dialog-success" aria-labelledby="dialog-success-title" aria-describedby="dialog-success-description">
                <DialogTitle className="dialog-success-title">
                    Registro Completado
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="dialog-success-description">
                        Se ha registrado correctamente con el nombre de usuario: {username}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className="btn green" onClick={() => navigate('/login')}>Iniciar Sesión</button>
                </DialogActions>
            </Dialog>
            <Footer/>
        </div>
    );
}
 
export default RegisterPage;