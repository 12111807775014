
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import logoceia3uco from '../../assets/uco-ceiA3.png';
import logoecovalia from '../../assets/logotipoecovalialetras.png';
import logoingeoliva from '../../assets/Ingeoliva-logo-sin-ingenieria-del-olivar-1024x412.jpg';
import logodacartec from '../../assets/logoalta2_DACARTEC.jpg';
import { Link as LinkRouter } from "react-router-dom";


const Footer = () => {

    return(
        <>
            <footer id="footer" className="footer">
                <div className='footer-container'>
                    <Grid container spacing={4} className="footer-box">
                        <Grid item lg={2} md={12} style={{ boxShadow:'none'}} className='footer-imageContainer'>
                            <Paper className='footer-imageWrapper'>
                                <img src={logoecovalia} alt="Logo Ecovalia" className='footer-image'/>
                            </Paper>
                        </Grid>
                        <Grid item lg={2} md={12} style={{ boxShadow:'none'}} className='footer-imageContainer'>
                            <Paper className='footer-imageWrapper'>
                                <img src={logoceia3uco} alt="Logo Universidad de Córdoba y del Campo de Excelencia Internacional agroalimentario" className='footer-image'/>
                            </Paper>
                        </Grid>
                        <Grid item lg={2} md={12} style={{ boxShadow:'none'}} className='footer-imageContainer'>
                            <Paper className='footer-imageWrapper'>
                                <img src={logoingeoliva} alt="Logo Ingeoliva" className='footer-image'/>
                            </Paper>
                        </Grid>
                        <Grid item lg={2} md={12} style={{ boxShadow:'none' }} className='footer-imageContainer maxWidthImport'>
                            <Paper className='footer-imageWrapper' >
                                <img src={logodacartec} alt="Logo Dacartec" className='footer-image'/>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div className='footer-feader'>
                    <a title="http://ec.europa.eu/agriculture/index_es.htm" href="http://ec.europa.eu/agriculture/index_es.htm" target="_blank" rel="noopener">Esta página web está cofinanciada con el Fondo Europeo Agrícola de Desarrollo Rural a través del Programa de Desarrollo Rural de Andalucía 2014-2022.</a>
                </div>
                <div className='footer-text'>
                    <div className='footer-text-container'>
                        <div>    
                            <LinkRouter to="/aviso-legal">Aviso Legal |</LinkRouter>   
                            <LinkRouter to="/proteccion-de-datos"> Política de Privacidad |</LinkRouter> 
                            <LinkRouter to="/condiciones-de-uso"> Condiciones de uso |</LinkRouter> 
                            <LinkRouter to="/consentimiento-explicito"> Consentimiento explícito |</LinkRouter>
                            <LinkRouter to="/aviso-cookies"> Aviso Cookies </LinkRouter> 
                        </div>
                        <p>
                        © {new Date().getFullYear()} TIC4BIO | V. {process.env.REACT_APP_VERSION_FRONT_T4B}    
                        </p>
                    </div>
                </div>
            
            </footer>
        </>
    );
}

export default Footer;